interface ChildrenRoute {
  root: string;
  params?: { [key: string]: string };
  children: RoutePathDefinitions;
}
interface RoutePathDefinitions {
  [key: string]: string | ChildrenRoute;
}

// CONSTANTS FOR ROUTE PATHS
export const ROUTE_PATHS = {
  notifications: 'notifications',
  currentOrders: {
    root: 'current-orders',
    params: {
      shiftId: 'shift-id',
      staffId: 'staff-id',
      company: 'c',
    },
    children: {
      newOrderList: 'new-order-list',
      add: 'add',
      edit: 'edit',
      worker: 'worker',
    },
  },
  orderHistory: {
    root: 'order-history',
    params: {
      shiftId: 'shift-id',
      staffId: 'staff-id',
    },
    children: {
      worker: 'worker',
    },
  },
  statistics: 'statistics',
  helpAndSupport: {
    root: 'help-and-support',
    params: {
      name: 'name',
    },
    children: {
      category: 'category',
      faq: 'faq',
    },
  },
  profile: {
    root: 'profile',
    params: {
      clientCode: 'client-code',
    },
    children: {
      notification: 'notification',
    },
  },
  orderTypes: 'order-types',
  administration: {
    root: 'administration',
    params: {
      clientCode: 'client-code',
    },
    children: {
      ward: 'ward',
      user: 'user',
      notification: 'notification',
      add: 'add',
      edit: 'edit',
    },
  },
  features: 'features',
  login: 'login-redirect',
  logout: 'log-out',
  accessDenied: 'access-denied',
  unauthorized: 'unauthorized',
  callback: 'callback',
  error: 'error',
  inactiveAccount: 'inactive-account',
  unverifiedEmail: 'unverified-email',
} as const satisfies RoutePathDefinitions;
