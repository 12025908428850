import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { map, Observable, switchMap, tap } from 'rxjs';
import { ROUTE_PATHS } from '@client-portal/routing';
import { AuthService } from '@auth0/auth0-angular';
import { UserService } from '@client-portal/services/user';
import { UserStatus } from '@client-portal/enums/user';

export const inactiveAccountGuard:
  | CanActivateFn
  | CanActivateChildFn = (): Observable<boolean> => {
  const authService = inject(AuthService);
  const userService = inject(UserService);
  const router = inject(Router);

  return authService.isAuthenticated$.pipe(
    switchMap(() => userService.userProfile$),
    map((user) => user.status),
    tap((status) => {
      if (status === UserStatus.Active) {
        userService.setUserStatusActive(true);
      } else {
        userService.setUserStatusActive(false);
        void router.navigateByUrl(ROUTE_PATHS.inactiveAccount);
      }
    }),
    map((status) => status === UserStatus.Active),
  );
};
