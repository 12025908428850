import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { map, Observable, switchMap, tap } from 'rxjs';
import { ROUTE_PATHS } from '@client-portal/routing';
import { AuthService } from '@auth0/auth0-angular';
import { UserService } from '@client-portal/services/user';

export const emailVerificationGuard:
  | CanActivateFn
  | CanActivateChildFn = (): Observable<boolean> => {
  const authService = inject(AuthService);
  const userService = inject(UserService);
  const router = inject(Router);

  return authService.isAuthenticated$.pipe(
    switchMap(() => authService.user$),
    map((user) => !!user?.email_verified),
    tap((emailVerified) => {
      if (emailVerified) {
        userService.setEmailVerified(true);
      } else {
        userService.setEmailVerified(false);
        void router.navigateByUrl(ROUTE_PATHS.unverifiedEmail);
      }
    }),
  );
};
