import { CanActivateChildFn, CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, tap } from 'rxjs';
import { ROUTE_PATHS } from '@client-portal/routing';

export const authGuard:
  | CanActivateFn
  | CanActivateChildFn = (): Observable<boolean> => {
  const authService = inject(AuthService);

  return authService.isAuthenticated$.pipe(
    tap((loggedIn) => {
      if (!loggedIn) {
        authService.loginWithRedirect({
          appState: { target: `/${ROUTE_PATHS.login}` },
        });
      }
    }),
  );
};
